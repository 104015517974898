import React, { useState } from "react";
import { Avatar, IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Line } from 'react-chartjs-2';


const lineChartOptions = {
    plugins: {
        legend: {
            display: false,
        }
    },
    scales: {
        y: {
            grid: {
                display: false,
            },
            gridLines: {
                drawBorder: false,
            },
            // color: "yellow",
            // ticks: {
            //     display: false,
            // }
        },
        x: {
            grid: {
                display: false,
            },
            gridLines: {
                drawBorder: false,
            },
            // color: "yellow",
            // ticks: {
            //     display: false,
            // }
        }
    },
}

const useStyles = makeStyles((theme) => ({
    componentContainer: {
        display: 'flex',
        justifyContent: 'center',
        // height: '250px',
        margin: '40px 0',
        position: 'relative'
    },
    chartContainer: {
        marginTop: '40px',
        top: '80px',
        maxHeight: '400px',
        width: '100%'
    },
    badge: {
        zIndex: 1,
        top: '-20px',
        position: 'absolute',

    }
}))

export const LevelHistory = (props) => {
    const classes = useStyles();
    const [dataset, setDataset] = useState(0)

    const levelDataByDomain = [
        {
            id: 1,
            labels: ['Jan', 'Feb', 'March', 'April', 'May'],
            data: [10, 8, 11, 13, 17],
            colorPrimary: '#dfb774',
            score: 3
        }
    ]

    const preparedLevelData = {
        labels: levelDataByDomain[0].labels,
        datasets: [{
            tension: 0.3,
            label: 'My First Dataset',
            data: levelDataByDomain[0].data,
            borderColor: levelDataByDomain[0].colorPrimary,
            borderWidth: 3,
            pointRadius: 0,
        }]
    }
    return (
        <div className={classes.componentContainer}>
            <div className={classes.badge}>
                {levelDataByDomain.map((domain) => {
                    return (
                        <IconButton
                            onClick={() => setDataset(i => i += 1)}>
                            <Avatar
                                key={domain.id}
                                sx={{ width: 60, height: 60, fontSize: '1em', color: 'white', fontSize: '1.2em', background: `radial-gradient(closest-side, ${domain.colorPrimary} 79%, transparent 80% 100%),conic-gradient(${domain.colorPrimary} ${(Math.random() * 100)}%, white 0)` }}>{domain.score}
                            </Avatar>
                        </IconButton>
                    )
                })}
            </div>
            <div className={classes.chartContainer}>
                <Line
                    options={lineChartOptions}
                    data={preparedLevelData}
                    {...props} />
            </div>
        </div>
    );
} 
