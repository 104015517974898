import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'authEmail',
    initialState: {
      value: {}
    },
    reducers: {
      addAuth: (state, action) => {
        state.value = action.payload.email
        // for each domain in action.payload.domain, add action.payload.score to the score
      }
    }
  })