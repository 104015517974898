import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { DataStore } from 'aws-amplify';
import { QuestionAnswer } from '../models';
import { Avatar, IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, PointElement, LineElement } from 'chart.js';

ChartJS.register(CategoryScale, BarElement, LinearScale, PointElement, LineElement); // Global. put it somewhere else

const barChartOptions = {
    plugins: {
        legend: {
            display: false,
        }
    },
    scales: {
        y: {
            grid: {
                display: false,
            },
            // display: false
            gridLines: {
                drawBorder: false,
            },
            // color: "yellow",
            // ticks: {
            //     display: false,
            // }
        },
        x: {
            grid: {
                display: false,
            },
            gridLines: {
                drawBorder: false,
            },
            // color: "yellow",
            //     ticks: {
            //         display: false,
            //     }
        }
    },
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        // height: '250px',
        margin: '40px 0',
        position: 'relative'
    },
    chartContainer: {
        marginTop: '50px',
        top: '80px',
        maxHeight: '400px',
        width: '100%'
    },
    badge: {
        zIndex: 1,
        top: '-20px',
        position: 'absolute',

        // borderRadius: '50%',
        // background: 'radial-gradient(closest-side, white 79%, transparent 80% 100%),conic-gradient(hotpink 75%, pink 0)'
    }
}))

export const XPHistory = (props) => {
    const classes = useStyles();

    const domainParam = props.domainParam;
    const userEmail = useSelector((state) => state.authEmail.value)

    const [data, setData] = useState([])
    const [labels, setLabels] = useState([])
    const [totalScore, setTotalScore] = useState(0)

    useEffect(() => {
        fetchPastQuestionAnswers();
    }, [domainParam]);

    // this should be in a shared place
    const fetchPastQuestionAnswers = async () => {
        try {
            let fetchedQAs;
            if (domainParam) {
                fetchedQAs = await DataStore.query(QuestionAnswer, qa => qa.and(qa => [
                    qa.userEmail.eq(userEmail),
                    qa.measuredTopics.contains(domainParam)]
                ));
            } else {
                fetchedQAs = await DataStore.query(QuestionAnswer, qa => qa.userEmail.eq(userEmail));
            }

            let _totalScore = 0;
            const QAsByDate = fetchedQAs.reduce((acc, qa) => {
                const date = new Date(qa.createdAt)
                const dateKey = date.toLocaleDateString(undefined, {month: "short", day: "numeric"})

                const score = qa.wasCorrect ? 1 : 0;
                _totalScore += score;
                if (acc[dateKey]) {
                    acc[dateKey].score += score;
                } else {
                    acc[dateKey] = { score, date }
                }
                return acc;
            }, {});

            const _labels =  Object.keys(QAsByDate).sort((a,b) => QAsByDate[a].date - QAsByDate[b].date)
            const _data = _labels.map((i) => QAsByDate[i].score)
            setLabels(_labels)
            setData(_data)
            setTotalScore(_totalScore)
        } catch (error) {
            console.error('Error fetching QuestionAnswers:', error);
        }
    };

    // if (!data || !labels) return null

    const preparedData = {
            labels,
            datasets: [{
                label: 'My First Dataset',
                data,
                borderColor: '#dfb774',
                borderWidth: 3,
                // backgroundColor: 'rgba(0, 99, 132, 0.5)',
            }]
        }

    return (
        <div className={classes.container}>
            <div className={classes.badge}>
                <IconButton>
                    <Avatar
                        sx={{ 
                            width: 60,
                            height: 60,
                            fontSize: '1em',
                            color: 'white',
                            fontSize: '1.2em',
                            backgroundColor: '#dfb774' ,
                            boxShadow: '0px 0px 19px #dfb774'
                            }}>
                            {totalScore}
                    </Avatar>
                </IconButton>
            </div>
            <div className={classes.chartContainer}>
                <Bar
                    options={barChartOptions} 
                    data={preparedData}
                    {...props} />
            </div>
        </div>
    );
}


