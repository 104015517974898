import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, CircularProgress, Typography, Paper } from '@mui/material';

import { completeWithSystem, completeWithSystemStream } from '../api/openai';
import { EndlessQuestionStream } from './Questions/EndlessQuestionStream';
import PowerMeterBar from './PowerMeterBar'
import ArcadeTimer from './ArcadeTimer'
import { DataStore } from 'aws-amplify';
import Score from './Score'
import { QuestionAnswer } from '../models';

import { matchRecursive, randomLeaf } from '../util/json';
import DomainScoreDiff from './DomainScoreDiff';
import { useSelector } from 'react-redux'
import { TopBar } from './TopBar';
import {
    useParams,
    Link
} from "react-router-dom";

import * as tree from '../scripts/tree.json';
import { makeStyles } from '@mui/styles';
import { textAlign } from '@mui/system';
import { set } from 'date-fns';


const topLevelDomains = [
    "Natural Sciences",
    "Mathematics",
    "Engineering and Technology",
    "Medicine and Health",
    "Social Sciences",
    "Humanities",
    "Language and Linguistics",
    "Literature",
    "Visual Arts",
    "Performing Arts",
    "Communication and Media",
    "Religion and Spirituality",
    "Education",
    "Law",
    "Business and Economics",
    "Political Science",
    "Environmental Studies",
    "Philosophy and Ethics",
    "Cultural Studies",
    "Interdisciplinary Studies"
];

const useStyles = makeStyles({
    gradient1: {
        width: "100vw",
        height: "100vh",
        background: "linear-gradient(334deg, #6b97f7, #7525e2, #f7137e)",
        backgroundSize: "180% 180%",
        animation: "gradient-animation 6s ease infinite"
      },
      "@keyframes gradient-animation": {
        "0%": { backgroundPosition: "0% 50%" },
        "50%": { backgroundPosition: "100% 50%" },
        "100%": { backgroundPosition: "0% 50%" }
      },
      gradient2: {

      },
    container: {
        maxWidth: '800px',
        margin: '0 auto'
    },
    input: {
        fontSize: '3rem',
        color: 'blue'
    },
    score: {
        fontSize: 24,
        // textShadow: '0.1rem 0.1rem #52a8e8',
        color: '#dfb774',
        fontWeight: 'bold',
    },
    correctRatio: {
        fontSize: 24,
        // textShadow: '0.1rem 0.1rem #dfb774',
        color: '#52a8e8',
        fontWeight: 'bold',
    },
    scoreConnector: {
        fontWeight: 'bold',
        fontSize: 24,
    },
    teachHeader: {
        position: 'relative',
    },
    scoreContainer: {
        textAlign: 'right',
        width: '100%',
        top: '-13px',
        padding: '15px',
        position: 'absolute',
        textAlign: 'right',
    },
    paper: {
        maxWidth: '800px',
        padding: '20px 40px 40px',
        margin: '20px',
        textAlign: 'left',
        '&::first-letter': {
            // fontSize: '1.5rem',
            fontWeight: 'bold',
            padding: '0 10px 5px 0',
            color: '#dfb774',
            initialLetter: '3 2',
            '-webkkit-initial-letter': '3 2',
            textShadow: '0.2rem 0.2rem #52a8e8'

        },
        //   '&::first-line': {
        //     fontVariant: 'small-caps', 
        //     fontWeight: 'bold',
        //     fontSize: '1.25rem',
        //   },
    },
})

// const difficultyLevels = [
//     "Early middle school level (7th-8th grade)",
//     "Late middle school level (9th grade)",
//     "Early high school level (10th grade)",
//     "Advanced high school level (11th-12th grade)",
//     "College freshman level (Undergraduate Year 1)",
//     "College sophomore level (Undergraduate Year 2)",
//     "College junior level (Undergraduate Year 3)",
//     "College senior level (Undergraduate Year 4)",
//     "Early graduate level (Master's Year 1)",
//     "Late graduate level (Master's Year 2)",
//     "PhD candidate level (PhD Year 1-2)",
//     "Advanced PhD candidate level (PhD Year 3-4)",
//     "Early postdoctoral level (1-2 years post-PhD)",
//     "Mid-career researcher level (3-5 years post-PhD)",
//     "Senior researcher level (6-10 years post-PhD)",
//     "Expert researcher level (10+ years post-PhD)"
// ]

const replaceFirstOccurrence = (str, search, replace) => {
    const index = str.indexOf(search);
    if (index !== -1) {
        return str.slice(0, index) + replace + str.slice(index + search.length);
    }
    return str;
}

export default function LearnHomePage(props) {
    const classes = useStyles();
    const { topic: topicParam } = useParams();
    const [content, setContent] = useState('');
    const [topics, setTopics] = useState([]);
    const [topic, setTopic] = useState("");
    // const [questions, setQuestions] = useState([])
    const [questionIndex, setQuestionIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    // const [levelRequested, setLevelRequested] = useState(1)
    // const [levelPresented, setLevelPresented] = useState(1)
    const [nextDiff, setNextDiff] = useState(1)
    const [running, setRunning] = useState(false)
    const [addTime, setAddTime] = useState(30)
    const [timeRemaining, setTimeRemaining] = useState(90); // this is causing extra rerenders
    const [score, setScore] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [domains, setDomains] = useState({});
    const [fact, setFact] = useState("");
    const [factWord1, setFactWord1] = useState("");
    const [randomTopic1, setRandomTopic1] = useState("");
    const [randomTopic2, setRandomTopic2] = useState("");
    const [chunk, setChunk] = useState("");
    const [correct, setCorrect] = useState("")
    const [correctRatio, setCorrectRatio] = useState("")

    const userEmail = useSelector((state) => state.authEmail.value)

    const fetchPastQuestionAnswers = async () => {
        try {
            const fetchedQAs = await DataStore.query(QuestionAnswer, qa => qa.userEmail.eq(userEmail));

            const today = new Date();
            const todayKey = today.toLocaleDateString(undefined, { month: "short", day: "numeric" })

            let todayScore = 0;
            let todayQAs = []
            let correct = 0;
            let incorrect = 0;
            fetchedQAs.filter(qa => {
                const qaDate = new Date(qa.createdAt)
                const qaDateKey = qaDate.toLocaleDateString(undefined, { month: "short", day: "numeric" })
                return todayKey === qaDateKey
            }).map(qa => {
                if (qa.wasCorrect) {
                    correct++;
                } else {
                    incorrect++;
                }
            })
            console.log(correct, incorrect)
            setCorrect(correct)
            setCorrectRatio(correct / (correct + incorrect))


            //     .reduce((todayScore, qa) => {
            //     const date = new Date(qa.createdAt)
            //     const dateKey = date.toLocaleDateString(undefined, {month: "short", day: "numeric"})

            //     if (dateKey !== todayKey) {
            //         return todayScore
            //     } else {
            //         const score = qa.wasCorrect ? 1 : 0;
            //         todayScore += score;
            //         todayQAs.push(qa)
            //         return acc;
            //     }
            // }, {});

            // console.log(QAsByDate)

            // const _labels =  Object.keys(QAsByDate).sort((a,b) => QAsByDate[a].date - QAsByDate[b].date)
            // const _data = _labels.map((i) => QAsByDate[i].score)
            // setLabels(_labels)
            // setData(_data)
            // setTotalScore(_totalScore)
        } catch (error) {
            console.error('Error fetching QuestionAnswers:', error);
        }
    };



    useEffect(() => {
        if (factWord1 === "") {
            setFactWord1(f => f + chunk)
        } else {
            setFact(fact => fact + chunk)
        }
    }, [chunk])

    useEffect(() => {

        const topics3 = [
            'volcaones',
            'dogs',
            'lions',
            'sea turtles',
            'galaxies',
            'astronomy',
            'classic rock',
            'construction',
            'wwii',
            'africa',
            'asia',
            'europe',
            'south america',
            'north america',
            'robotics',
            'art',
            'sports',
            'basketball',
            'music theory',
            'music history',
            'comedy',
            'mythology',
            'ancient history',
            'biology',
            'chemistry',
            'physics',
            'mathematics',
            'geology',
            'geography',
            'medicine',
            'airplanes',
            'cars',
            'trains',
            'hawaii',
            'alaska',
            'california',
            'whales',
            'dolphins',
            'computers',
            'programming',
            'money',
            'economics',
            'united states',
            'canada',
            'mexico',
            'rockets',
            'space',
            'the moon',
            'automobiles',
            'electronics',
            'the internet',
            '90s pop musics',
            '90s rock music',
            '90s rap music',
            'artificial intelligence',
            'the human body',
            'the human brain',
            'the human heart',
            'the human lungs',
            'the human liver',
            'the human kidneys',
            'the human stomach',
            'the human intestines',
            'the human pancreas',
            'crime',
            'the law',
            'the government',
            'earth',
            'the solar system',
            'oceans',
            'the sun',
            'the stars',
            'the universe',
            'the milky way',
            'chemistry',
            'architecture',
            'engineering',
            'grilling',
            'cooking',
            'sports',
            'gardening',
            'plants',
            'trees',
            'flowers',
            'birds',
            'cats',
            'mountains',
            'rivers',
            'lakes',
            'monkeys',
            'apes',
            'gorillas',
            'chimpanzees',
            'humans',
            'dinosaurs',
            'babies',
            'children',
            'teenagers',
            'adults',
            'senior citizens',
            'cooking and baking',
            'the olympics',
            'the world cup',
            'the super bowl',
            'literature',
            'theater',
            'movies',
            'fashion',
            'clothing',
            'shoes',
            'the way thing are',
            'why',
            'how',
            'when',
            'where',
            'who',
            'what',
            'soccer',
            'why things are the way they are',
        ]
        const randomTopic1 = topics3[Math.floor(Math.random() * topics3.length)];
        let topics4 = topics3.filter(i => i !== randomTopic1)
        const randomTopic2 = topics4[Math.floor(Math.random() * topics4.length)];
        setRandomTopic1(randomTopic1)
        setRandomTopic2(randomTopic2)



        const get = async () => {

            const prompt = `Return a fact about ${randomTopic1} that would be interesting and unlikely to be known by the average person. The fact should be 1-3 sentences. Don't include the phrase "lesser known fact"`

            const reader = await completeWithSystemStream(prompt, "")
            while (true) {
                const { value, done } = await reader.read();
                if (done) break; // does not work
                const dt = value.split("data: ")


                const newContent = dt
                    .filter(i => i !== "" && i !== "[DONE]\n\n")
                    .map(i => JSON.parse(i))
                    .filter(i => i.choices[0].delta.content !== undefined)
                    .map(i => i.choices[0].delta.content)
                    .join("")
                setChunk(newContent)
                // if (factWord1 === "") {
                //     setFactWord1(f => f + newContent) 
                // } else {
                //     setFact(fact => fact + newContent)
                // }
                //     e
                // setFact(fact => {
                //     if (fact === "") {
                //         return "<span>" + newContent + "</span>"
                //     } else {
                //         return fact + newContent
                //     }
                // })
                const lastData = dt[dt.length - 1]
                if (lastData === "[DONE]\n\n") {
                    // setQuestionIndex(questionIndex + 1)
                    break
                }

                // const data2 = JSON.parse(data)
                // setStreamChunk(data2.choices[0].delta.content)
            }
        }

        get()
        fetchPastQuestionAnswers()

    }, [])

    const domainScoreState = useSelector((state) => state.domainScore.value)
    // const [streamChunk, setStreamChunk] = useState("");
    // const [stream, setStream] = useState("");


    // useEffect(() => {
    //     // const match = matchRecursive(streamChunk, '{...}')
    //     // console.log(match)
    //     if (streamChunk === "") return
    //     setStream(stream + streamChunk)
    // }, [streamChunk])

    // useEffect(() => {
    //     // console.log(stream)
    //     if (stream === "") return
    //     console.log(stream.split("\n"))
    //     const match = matchRecursive(stream, '{...}')
    //     // console.log(match)
    //     try {
    //         let {type, question_text, correctAnswer, answers } = JSON.parse(match)

    //         if (type !== undefined && question_text === undefined) {
    //             stream.split("\n").forEach(s => {
    //                 if (s.includes("question_text")) {
    //                     const q_text_parts = s.split("question_text\":")
    //                     question_text =  q_text_parts[q_text_parts.length - 1]
    //                 }
    //             })
    //         }

    //         if (type !== undefined && question_text !== undefined) {
    //             if (answers === undefined) {
    //                 stream.split("\n").forEach(s => {
    //                     if (s.includes("answers")) {
    //                         const answer_parts = s.split("answers\":")
    //                         answers = answer_parts[answer_parts.length - 1].split(",").map(i => i.trim().replace("\[", "").replaceAll("\"", ""))
    //                         console.log(s, answer_parts, answers)
    //                         // answers =  answer_parts[answer_parts.length - 1]
    //                     }
    //                 })
    //             }
    //             setQuestions([{type, question_text, correctAnswer, answers}])
    //         }
    //     } catch (e) {
    //         console.log(e, match, stream)
    //     }
    // }, [stream])


    useEffect(() => {
        const timerId = setInterval(() => {
            setTimeRemaining((prevTimeRemaining) => {
                if (!running) {
                    clearInterval(timerId);
                    return prevTimeRemaining
                }
                if (prevTimeRemaining <= 0) {
                    clearInterval(timerId);
                    return 0;
                }
                return prevTimeRemaining - 1;
            });
        }, 1000);

        return () => clearInterval(timerId);
    }, [running]);

    // useEffect(() => {
    //     if (questionIndex === 0) return
    //     const run = async () => {
    //         const previousQuestions = questions.map(q => {
    //             let question_text = q.question_text
    //             if (q.type !== 'multiple_choice') {
    //                 question_text = replaceFirstOccurrence(q.question_text, '___', q.correctAnswers.first_blank)
    //                 question_text = replaceFirstOccurrence(question_text, '___', q.correctAnswers.second_blank)
    //             }

    //             return `{"previous_problem": "${question_text}"},`
    //         }).join('\n')
    //         const qJson = await getAQuestion(previousQuestions)
    //         // setQuestions([...questions, qJson])
    //     }
    //     run()
    //     setLevelRequested(levelRequested + nextDiff)
    // }, [questionIndex]);

    const submit = async () => {
        setTopic(content)
        setLoading(true)
        // const topics = await completeWithSystem(`Generate 10 subtopics of ${content} and return them as a json array: subtopics: []`, "")
        setTopics([
            "Whale classification and species diversity",
            "Anatomy and physiology of whales",
            "Whale behavior and communication",
            "Migration patterns and ranges of different whale species",
            "Life cycle and reproduction of whales",
            "Whale hunting and conservation efforts",
            "Ecosystem roles and interactions of whales",
            "Whale watching and tourism",
            "Cultural significance of whales in different societies",
            "Study and research methods for understanding whales"
        ])
        // const qJson = await getAQuestion()

        // setQuestions([qJson])
        // setQuestionIndex(questionIndex + 1)
        setLoading(false)
        setRunning(true)
    }

    const handleKeyPress = async (event) => {
        if (event.key !== 'Enter') {
            return
        }
        await submit()

        // // const q2 = await completeWithSystem(prompt2, content)
        // const qJson2 = await getAQuestion()
        // // const qJson2 = JSON.parse(q2)
        // setQuestions([...qJson, ...qJson2])

        // // const q3 = await completeWithSystem(prompt2, content)
        // const qJson3 = await getAQuestion()
        // // const qJson3 = JSON.parse(q3)
        // setQuestions([...qJson, ...qJson2, ...qJson3])
    };

    const nextQuestion = async (diff) => {
        // setLevelPresented(levelRequested)
        setNextDiff(diff)
        setQuestionIndex(questionIndex + 1)
        setTimeRemaining(timeRemaining + 5)

        // const previousQuestions = questions.map(q => `- "text": ${q.text}`).join('\n')
        // const qJson = await getAQuestion(previousQuestions)

        // setQuestions([...questions, ...qJson])
    }

    const questionToggle = (action, qScore, domainScore) => {
        if (action === "start") {
            setStartTime(Date.now())
        } else {
            const timeDelta = Date.now() - startTime
            setScore(score + Math.floor(qScore * Math.max(1000, (30000 - timeDelta)) / 10))
            setDomains(domainScore)
        }
    }

    console.log(topic, topicParam)


    return (
        // <div className={classes.gradient1}>
        <div>
            <Box sx={{ padding: 2 }}>
                <TopBar />

                

                {!(topic !== "" || props.random || topicParam) &&
                    <div className={classes.container}>
                        <Box sx={{ margin: '20px 0' }}>

                            <Typography sx={{
                                fontSize: '1.8rem',
                                // textShadow: '0.1rem 0.1rem #52a8e8',
                                color: '#dfb774',
                                '@media (min-width:600px)': {
                                    fontSize: '2.3rem',
                                },
                                // fontWeight: 'lighter'
                            }} variant="h4" color="text.secondary" align="left">
                                Teach me
                            </Typography>
                        </Box>
                        <Paper className={classes.paper} sx={{ margin: '0 auto' }}>
                            {factWord1 &&
                                <Typography sx={{ fontSize: 18 }} variant="body1" color="text.secondary" align="left">
                                    <span>{factWord1}</span> {fact}
                                </Typography>
                            }
                        </Paper>

                    </div>
                }
                {!props.random && (topic === "" && !topicParam) &&
                    <Box sx={{ maxWidth: '800px', margin: '40px auto' }}>
                        <div className={classes.teachHeader}>
                            <Typography sx={{
                                fontSize: '1.8rem',
                                // textShadow: '0.1rem 0.1rem #52a8e8',
                                color: '#dfb774',
                                '@media (min-width:600px)': {
                                    fontSize: '2.3rem',
                                },
                                // fontWeight: 'lighter'
                            }} variant="h4" color="text.secondary" align="left">
                                Quiz me
                            </Typography>
                            <div className={classes.scoreContainer}>
                                <span className={classes.score}>
                                    {correct}
                                </span>
                                <span className={classes.scoreConnector}> - </span>
                                <span className={classes.correctRatio}>
                                    {(correctRatio * 100).toFixed(0)}%
                                </span>
                            </div>
                        </div>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <TextField
                                autoComplete='off'
                                rows={1}
                                value={content}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '1rem',

                                    }
                                }

                                }
                                // color="#dfb774" 
                                InputProps={{
                                    endAdornment: <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            margin: '5px', padding: '5px 25px',
                                            // backgroundColor: '#52a8e8' 
                                        }}
                                        onClick={submit}>
                                        Submit
                                    </Button>,
                                    inputProps: {
                                        style: {
                                            fontSize: '1rem',
                                            // textAlign: 'center',
                                        },
                                    }
                                }}
                                //   sx={{
                                //     textAlign: 'center',
                                //   }}
                                onChange={(e) => setContent(e.target.value)}
                                label="A topic of your choice"
                                onKeyPress={handleKeyPress}
                                sx={{ margin: '10px', backgroundColor: '#1e1e1e' }}
                            />
                            <Button
                                component={Link}
                                variant="contained"
                                color="primary"
                                sx={{
                                    margin: '10px', padding: '12px 25px',
                                    // backgroundColor: '#52a8e8' 
                                }}
                                to={`/search/${encodeURIComponent(randomTopic1)}`}
                                onClick={() => { console.log('a') }}>
                                {randomTopic1}
                            </Button>
                            <Button
                                component={Link}
                                variant="contained"
                                color="primary"
                                sx={{
                                    margin: '10px', padding: '12px 25px',
                                    // backgroundColor: '#52a8e8' 
                                }}
                                to={`/search/${encodeURIComponent(randomTopic2)}`}
                                onClick={() => console.log('a')}>
                                {randomTopic2}
                            </Button>

                        </Box>
                        {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={submit}
                        endIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null}>
                        {'Prove it'}
                    </Button> */}
                    </Box>
                }

                {/* <ArcadeTimer timeRemaining={timeRemaining} />
            <Score score={score} /> */}
                {/* <PowerMeterBar percentage={levelRequested / difficultyLevels.length * 100} /> */}

                {/* if we have questions, then display */}
                {(topic !== "" || props.random || topicParam) &&
                    <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
                        <EndlessQuestionStream
                            topic={topic || topicParam}
                            random={props.random}
                            nextQuestion={nextQuestion}
                            questionToggle={questionToggle}
                            questionLoading={(loading) => setRunning(!loading)} />
                    </Box>
                }
                {/* <DomainScoreDiff domains={domainScoreState} /> */}
            </Box>
        </div>
    )
}