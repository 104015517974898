import React from 'react';
import {Grid, Box} from '@mui/material';
import { makeStyles } from '@mui/styles';



const useStyles = makeStyles((theme) => ({
    answerBox: {
        border: '1px solid gray',
        padding: theme.spacing(2),
        margin: theme.spacing(1),
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
    correctAnswerBox: {
        backgroundColor: 'green',
        color: 'white',
        fontWeight: 'bold',
    },
    incorrectAnswerBox: {
        backgroundColor: 'red',
        color: 'white',
        fontWeight: 'bold',
    }
}));

export const DoubleBlankQuestion = (props) => {
    const classes = useStyles();
    const handleAnswerClick = props.handleAnswerClick
    const currentQuestionData = props.question
    const currentAnswers = currentQuestionData.answers;
    const currentSelected = props.currentSelected;
    const isCorrect1 = currentSelected !== undefined && currentAnswers.first_blank[currentSelected[0]] === currentQuestionData.correctAnswers.first_blank;
    const isCorrect2 = currentSelected !== undefined && currentAnswers.second_blank[currentSelected[1]] === currentQuestionData.correctAnswers.second_blank;
    const hasAnswered1 = currentSelected !== undefined && currentSelected[0] !== undefined;
    const hasAnswered2 = currentSelected !== undefined && currentSelected[1] !== undefined;

    return (
        <Grid container spacing={2}>
            {currentAnswers.first_blank.map((answer, answerIndex) => {
                const firstIsCorrect = currentQuestionData.correctAnswers.first_blank === answer;
                const isSelectedAnswer = currentSelected !== undefined && currentSelected[0] === answerIndex;

                let answerBoxClass = classes.answerBox;
                if (hasAnswered1) {
                    if (isSelectedAnswer && isCorrect1) {
                        answerBoxClass += ` ${classes.correctAnswerBox}`;
                    } else if (isSelectedAnswer && !isCorrect1) {
                        answerBoxClass += ` ${classes.incorrectAnswerBox}`;
                    } else if (!isSelectedAnswer && firstIsCorrect) {
                        answerBoxClass += ` ${classes.correctAnswerBox}`;
                    }
                }

                return (
                    <Grid key={answerIndex} item xs={6} sm={3}>
                        <Box
                            className={answerBoxClass}
                            onClick={() => handleAnswerClick(answerIndex, 0, firstIsCorrect)}
                        >
                            {answer}
                        </Box>
                    </Grid>
                );
            })}
            {currentAnswers.second_blank.map((answer, answerIndex) => {
                const secondIsCorrect = currentQuestionData.correctAnswers.second_blank === answer;
                const isSelectedAnswer = currentSelected !== undefined && currentSelected[1] === answerIndex;

                let answerBoxClass = classes.answerBox;

                if (hasAnswered2) {
                    if (isSelectedAnswer && isCorrect2) {
                        answerBoxClass += ` ${classes.correctAnswerBox}`;
                    } else if (isSelectedAnswer && !isCorrect2) {
                        answerBoxClass += ` ${classes.incorrectAnswerBox}`;
                    } else if (!isSelectedAnswer && secondIsCorrect) {
                        answerBoxClass += ` ${classes.correctAnswerBox}`;
                    }
                }

                return (
                    <Grid key={answerIndex} item xs={6} sm={3}>
                    <Box
                        className={answerBoxClass}
                        onClick={() => handleAnswerClick(answerIndex, 1, secondIsCorrect)}
                    >
                        {answer}
                    </Box>
                </Grid>
                );
            })}
        </Grid>
    )
}