import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        // justifyContent: 'center',
        minHeight: '25px',
        margin: '15px 0',
    },
    box: {
        width: '25px',
        height: '25px',
        margin: '0 5px',
        borderRadius: '3px',
    },
    correct: {
        backgroundColor: '#dfb774',
        border: '2px solid #dfb774'
    },
    incorrect: {
        backgroundColor: 'gray',
        border: '2px solid gray'
    },
    undecided: {
        border: '2px solid gray'
    }
})

export const ScoreBar = (props) => {
    const classes = useStyles()
    const pastQAResults = props.pastQAResults
    const [boxes, setBoxes] = React.useState([])

    // const boxes = fillArray(pastQAResults, 25)


    const animate = () => {
        let start;
        let duration = 1000; // 1 second
    
        function easeIn(t) {
          return t * t;
        }
    
        function frame(timestamp) {
          if (!start) start = timestamp;
          let elapsed = timestamp - start;
    
          let progress = Math.min(elapsed / duration, 1);
          let easedProgress = easeIn(progress);
          
          let currentLength = Math.floor(easedProgress * 7);
    
          setBoxes(Array.from({ length: currentLength }));
    
          if (progress < 1) {
            window.requestAnimationFrame(frame);
          }
        }
    
        window.requestAnimationFrame(frame);
    }

    useEffect(() => {
        animate()
      }, []);

      useEffect(() => {
        if (pastQAResults.length === 0) {
            setBoxes([])
            animate()
            return
        }
        // combine pastQAResults with boxes, removing as many elements from the front of boxes as there are elements in pastQAResults
        const numToRemove = pastQAResults.length
        const newBoxes = [...boxes]
        newBoxes.splice(0, numToRemove)

        const newPastQAResults = [...pastQAResults]

        const combined = newPastQAResults.concat(newBoxes)

        setBoxes(combined)

        console.log("pastQAResults: ", pastQAResults, combined, newBoxes)

        // const newBoxes = [...boxes]
        // const newPastQAResults = [...pastQAResults]
        // const numToRemove = newPastQAResults.length - newBoxes.length
        // newPastQAResults.splice(0, numToRemove)
        // newBoxes.splice(0, numToRemove)
      }, [pastQAResults])

    return (
        <div>
            <div className={classes.container}>
                {boxes.map((result) => {
                    if (result === 1) {
                        return <div className={classes.box + " " + classes.correct}></div>
                    } else if (result === -1) {
                        return <div className={classes.box + " " + classes.incorrect}></div>
                    } else {
                        return <div className={classes.box + " " + classes.undecided}></div>

                    }
                })}
            </div>
        </div>
    );
}