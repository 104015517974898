import { createSlice } from '@reduxjs/toolkit'
import { domainKeyToPathKeys, domainKeyToName, domainKeyToPresentedName } from '../components/Questions/util';
import * as tree from '../scripts/tree.json';

export const domainScoreSlice = createSlice({
    name: 'domainScore',
    initialState: {
      value: {}
    },
    reducers: {
      addDomainScore: (state, action) => {
        // for each domain in action.payload.domain, add action.payload.score to the score
        for (const key in action.payload.domains) {
         
          domainKeyToPathKeys(key).forEach(k => {
            const name = domainKeyToName(tree, k)
            if (state.value[name] === undefined) state.value[name] = { score: 0, presentation: domainKeyToPresentedName(tree, k) }
            state.value[name].score += 1
          })
          // console.log(domainKeyToSubTree(tree, key.split("-")[0].trim()))
          
        }
      }
    }
  })