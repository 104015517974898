import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import {
  MemoryRouter,
  Route,
  Routes,
  Link,
  matchPath,
  useLocation,
} from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      '&:hover': {
        textDecoration: 'underline',
        textUnderlineOffset: '6px',
      },
    },
    active: {
        textDecoration: 'underline',
        textUnderlineOffset: '6px',
    }
  }));


function Router(props) {
  const { children } = props;
  if (typeof window === 'undefined') {
    return <StaticRouter location="/drafts">{children}</StaticRouter>;
  }

  return (
    <MemoryRouter initialEntries={['/drafts']} initialIndex={0}>
      {children}
    </MemoryRouter>
  );
}

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export function TopBar() {
    const classes = useStyles()
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  const routeMatch = useRouteMatch(['/shuffle', '/search', '/foryou', '/history/xp']);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Tabs value={currentTab} centered sx={{marginBottom: '15px'}}>
      <Tab label="Shuffle" value="/shuffle" to="/shuffle" component={Link} />
      <Tab label="Search" value="/search" to="/search" component={Link} />
      <Tab label="History" value="/history/xp" to="/history/xp" component={Link} />
    </Tabs>
  );
}