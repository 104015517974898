import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator, View, Image, Text } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import './index.css';
import { Provider } from 'react-redux'

import { store } from './store'

// Amplify.Logger.LOG_LEVEL = 'DEBUG'
Amplify.configure(awsconfig);


const components = {
  Header() {
    // const { tokens } = useTheme();

    return (
      <View textAlign="center">
      {/* <View textAlign="center" padding={tokens.space.large}> */}
        <Image
          alt="Amplify logo"
          // src="https://docs.amplify.aws/assets/logo-dark.svg"
          // src="%PUBLIC_URL%/apple-touch-icon.png"
          src={process.env.PUBLIC_URL + '/dog_logo_transparent.png'}
          style={{ width: '150px', height: '150px' }}
        />
      </View>
    );
  },

  Footer() {

    return (
      <View textAlign="center">
        <Text className='right-reserved'>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },
}

const AuthedApp = withAuthenticator(App, { initialState: "signUp", components }) 


ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode>
    <BrowserRouter>
      <AuthedApp /> 
    </BrowserRouter>
  </React.StrictMode>
  </Provider>, 
  document.getElementById('root')
);

