import React, { useEffect, useState } from 'react';
import { Typography, Paper, Box, Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { completeWithSystem2Stream, completeWithSystemStream, completeWithSystem, getNextTopics, getExplanationStream } from '../../api/openai'
import { matchRecursive, randomLeaf } from '../../util/json';
import { default as tree } from '../../scripts/tree.json';
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion';
import { DoubleBlankQuestion } from './DoubleBlankQuestion';
import { flatten, topNLevels, domainKeyToSubTree, domainKeyToPathKeys, domainKeyToName } from './util';
import { useDispatch } from 'react-redux'
import { jsonrepair } from 'jsonrepair'
import { answeredQuestionsSlice } from '../../store';
import { set } from 'date-fns';
import { ScoreBar } from '../ScoreBar';
import { is } from 'date-fns/locale';
import { useSelector } from 'react-redux'
import {
    Link
  } from "react-router-dom";


  function capitalizeFirstLetter(str) {
    if (str.length === 0) return str;
    // Split the string into an array of words
    let words = str.split(" ");
  
    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
  
    // Join the words back into a string
    let capitalizedStr = words.join(" ");
  
    return capitalizedStr;
  }

const numOfMultipleChoiceOptions = 4

const { addAnsweredQuestion, reset } = answeredQuestionsSlice.actions

const difficultyLevels = [
    "Early middle school level (7th-8th grade)",
    "Late middle school level (9th grade)",
    "Early high school level (10th grade)",
    "Advanced high school level (11th-12th grade)",
    "College freshman level (Undergraduate Year 1)",
    "College sophomore level (Undergraduate Year 2)",
    "College junior level (Undergraduate Year 3)",
    "College senior level (Undergraduate Year 4)",
    "Early graduate level (Master's Year 1)",
    "Late graduate level (Master's Year 2)",
    "PhD candidate level (PhD Year 1-2)",
    "Advanced PhD candidate level (PhD Year 3-4)",
    "Early postdoctoral level (1-2 years post-PhD)",
    "Mid-career researcher level (3-5 years post-PhD)",
    "Senior researcher level (6-10 years post-PhD)",
    "Expert researcher level (10+ years post-PhD)"
]

const getPrompt = (question, domains) => {
    const prompt = `
        You should provide back
        - the domains that the question falls under, e.g. "2.1.1.1 - Neuroscience"
        - an explanation of why you chose those domains

        ${question}

        Domains:
        ${domains}

        return the following JSON object back, with the keys filled in with your response. 
        return nothing else other than the JSON object.

        { "domains": ["x"], "explanation": "x" }
    `
    return prompt
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '30px 30px 60px 20px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    buttonContainer: {
        marginTop: theme.spacing(5),
        display: 'flex',
        justifyContent: 'center',
    },
    question: {
        textAlign: 'left',
    },
    topic: {
        textAlign: 'left',
        // color: 'gray',
    },
    explanation: {
        padding: '20px 40px 20px',
        '&::first-letter': {
            // fontSize: '1.5rem',
            fontWeight: 'bold',
            padding: '0 10px 5px 0',
            color: '#dfb774',
            initialLetter: '3 2',
            '-webkkit-initial-letter': '3 2',
            textShadow: '0.25rem 0.25rem #52a8e8'
        },
    }
}));

export const EndlessQuestionStream = (props) => {
    const classes = useStyles();
    const [currentQuestion, setCurrentQuestion] = React.useState(0);
    const [requestedQuestion, setRequestedQuestion] = React.useState(0);
    const [selected, setSelected] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [nextDiff, setNextDiff] = React.useState(1);
    const [questionScore, setQuestionScore] = React.useState({});
    const [domainScore, setDomainScore] = React.useState({});
    const [questions, setQuestions] = useState([])
    const [questionIndex, setQuestionIndex] = useState(0)
    const [streamChunk, setStreamChunk] = useState("");
    const [stream, setStream] = useState("");
    const [levelRequested, setLevelRequested] = useState(1)
    const [loadingCorrectAnswerStartTime, setLoadingCorrectAnswerStartTime] = useState(0)
    const [loadingCorrectAnswerStopTime, setLoadingCorrectAnswerStopTime] = useState(0)
    const [startLessonTime, setStartLessonTime] = useState(0)
    const [lessonCompleteTime, setLessonCompleteTime] = useState(0)
    const [correctAnswerWaiting, setCorrectAnswerWaiting] = useState("")
    const [correctAnswerPlacement, setCorrectAnswerPlacement] = useState(-1)
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)
    const [correctAnswerDisplayed, setCorrectAnswerDisplayed] = useState(false)
    const [incorrectAnswersWaiting, setIncorrectAnswersWaiting] = useState([])
    const [pastQAResults, setPastQAResults] = useState([]);
    const [topDomainScores, setTopDomainScores] = useState([0,0,0,0,0,0]);
    const [actualTopic, setActualTopic] = useState("");
    const [actualTopicId, setActualTopicId] = useState("");
    const [lessonComplete, setLessonComplete] = useState(false);
    const [nextTopics, setNextTopics] = useState({});
    const [loadingNextTopics, setLoadingNextTopics] = useState(false);
    const [topic, setTopic] = useState("")
    const [displayExplanation, setDisplayExplanation] = useState(false)
    const [description, setDescription] = useState({})
    const [explanationStreamChunk, setExplanationStreamChunk] = useState("")
    const previousQuestions = useSelector((state) => state.answeredQuestions.value)

    const dispatch = useDispatch()

    useEffect(() => {
        if (explanationStreamChunk.includes("[DONE]")) {
            return
        }

        setQuestions(questions => {
            const updatedQuestions2 = [...questions]
            if (updatedQuestions2[questionIndex] === undefined) {
                updatedQuestions2[questionIndex] = {}
            }
            console.log(explanationStreamChunk, updatedQuestions2[questionIndex].explanation, (updatedQuestions2[questionIndex].explanation || "") + explanationStreamChunk)
            updatedQuestions2[questionIndex] = {
                ...updatedQuestions2[questionIndex],
                explanation: (updatedQuestions2[questionIndex].explanation || "") + explanationStreamChunk
            }
            return updatedQuestions2
        })

        // setDescription(d => {
        //     if (d[currentQuestion] === undefined) d[currentQuestion] = {}
        //     d[currentQuestion] + explanationStreamChunk
        // })
    }, [explanationStreamChunk])

    const getDescription = async () => {
        
        const currentQuestionData = questions[currentQuestion];
        console.log(currentQuestionData)
        const prompt = `"${currentQuestionData.question_text}" correct answer: ${currentQuestionData.correctAnswerWaiting}}`
        const reader = await getExplanationStream(prompt)
            while (true) {
                const { value, done } = await reader.read();
                if (done) break; // does not work
                const dt = value.split("data: ")

                const newContent = dt
                    .filter(i => i !== "" && i !== "[DONE]\n\n")
                    .map(i => JSON.parse(i))
                    .filter(i => i.choices[0].delta.content !== undefined)
                    .map(i => i.choices[0].delta.content)
                    .join("")
                setExplanationStreamChunk(newContent)

                const lastData = dt[dt.length - 1]
                if (lastData === "[DONE]\n\n") {
                    // setQuestionIndex(questionIndex + 1)
                    setExplanationStreamChunk(lastData)
                    break
                }

                // const data2 = JSON.parse(data)
                // setStreamChunk(data2.choices[0].delta.content)
            }
    }

    // measure load time for the correct answer
    // t1 - when we have the key 
    // t2 - when we have the whole value 

    //display the correct answer last
    // start displaying incorrect answers immediately
    // when the question is done loading, display the correct answer by
    // - replaying the stream? 
    // or 
    // - simulating the stream?
    //  -- if we know how long it took to load, and how big the chunks were on average, then we could simulate 
    // when they are done, display the correct answer 

    // append new streamChunk to stream
    useEffect(() => {
        // const match = matchRecursive(streamChunk, '{...}')
        // console.log(match)
        if (streamChunk === "") return
        setStream(stream + streamChunk)
    }, [streamChunk])

    useEffect(() => {
        if (loadingCorrectAnswerStopTime === 0) return
        console.log(loadingCorrectAnswerStopTime - loadingCorrectAnswerStartTime)
    }, [loadingCorrectAnswerStopTime])

    // process stream into questions
    useEffect(() => {
        console.log(stream)
        // remove a match for the regex ^[^{}]*(?=\{)
        const streamFiltered = stream.replace(/^[^{}]*(?=\{)/, "")

        if (streamFiltered === "") {
            console.log("return", streamFiltered)
            return
        }
        let doneLoading = false
        if (streamFiltered.includes("[DONE]")) {
            doneLoading = true
            setShowCorrectAnswer(true)
            getDescription()
            return
            streamFiltered.replace("[DONE]\n\n", "")  // hack
        }
        // const matchPreRepair = matchRecursive(streamFiltered, '{...}')
        const matchPreRepair = streamFiltered
        // let loadingCorrectAnswerStopTime = 0
        // let loadingCorrectAnswerStartTime = 0
        try {
            const match = jsonrepair(matchPreRepair)
            console.log(match)
            let parsed = JSON.parse(match)
            if (parsed.length === undefined) parsed = [parsed]
            const found = {}
            console.log(parsed)
            parsed.forEach(m => {
                const { type, questionText, correctAnswer, incorrectAnswers } = m
                if (type !== undefined) found["type"] = type
                if (questionText !== undefined) {
                    if (correctAnswerPlacement === -1) {
                        // get a random number between 0 and 4
                        const random = Math.floor(Math.random() * numOfMultipleChoiceOptions)
                        console.log(random)
                        setCorrectAnswerPlacement(random)

                    }
                    found["questionText"] = questionText
                }
                if (correctAnswer !== undefined) {
                    if (loadingCorrectAnswerStartTime === 0) {
                        setLoadingCorrectAnswerStartTime(Date.now())
                    }
                    found["correctAnswerWaiting"] = correctAnswer
                    setCorrectAnswerWaiting(correctAnswer)
                }
                if (incorrectAnswers !== undefined) {
                    if (loadingCorrectAnswerStopTime === 0) {
                        setLoadingCorrectAnswerStopTime(Date.now())
                    }
                    found["incorrectAnswers"] = incorrectAnswers
                }
            })

            let { type, questionText, correctAnswerWaiting, incorrectAnswers } = found
            console.log(found)
            // if (type !== undefined && question_text === undefined) {
            //     // comma doesn't work because commas can be in the question or answer text
            //     // newlines dont' work because newlines can sometimes be after and before the braces
            //     // .. so what to do? 
            //     stream.split(",").forEach(s => {
            //         if (s.includes("question_text")) {
            //             const q_text_parts = s.split("question_text\":")
            //             question_text = q_text_parts[q_text_parts.length - 1]
            //         }
            //     })
            // }

            if (type !== undefined && questionText !== undefined) {
                // if (correctAnswer === undefined) {
                //     stream.split("\n").forEach(s => {
                //         if (s.includes("correctAnswer")) {
                //             console.log("correctAnswer found 2")
                //             const answer_parts = s.split("correctAnswer\":")
                //             correctAnswer = answer_parts[answer_parts.length - 1]
                //         }
                //     })
                // }
                // if (incorrectAnswers === undefined) {
                //     stream.split("\n").forEach(s => {
                //         if (s.includes("incorrectAnswers")) {
                //             const answer_parts = s.split("incorrectAnswers\":")
                //             incorrectAnswers = answer_parts[answer_parts.length - 1].split(",").map(i => i.trim().replace("\[", "").replaceAll("\"", ""))
                //         }
                //     })
                // }
                // const updatedQuestions = questions
                // updatedQuestions[questionIndex] = { type, question_text, correctAnswer, incorrectAnswers }
                let incorrectAnswersLoaded;
                if (incorrectAnswers === undefined) {
                    incorrectAnswersLoaded = 0
                } else if (doneLoading) {
                    incorrectAnswersLoaded = incorrectAnswers.length
                } else {
                    incorrectAnswersLoaded = incorrectAnswers.length - 1
                }
                const show = correctAnswerWaiting !== undefined && incorrectAnswers !== undefined && incorrectAnswersLoaded >= correctAnswerPlacement
                console.log(show, correctAnswerWaiting, incorrectAnswers, incorrectAnswersLoaded, correctAnswerPlacement, doneLoading)
                setShowCorrectAnswer(show)
                // if correct answer is the 3rd, then we should stop adding after the 2nd incorrect answer, until it is displayed 
                if (!correctAnswerDisplayed && incorrectAnswers !== undefined && incorrectAnswersLoaded >= correctAnswerPlacement) {
                    // place additional incorrectAnswers into a pending state
                    const beforeCorrect = incorrectAnswers.slice(0, correctAnswerPlacement);
                    const afterCorrect = incorrectAnswers.slice(correctAnswerPlacement);
                    incorrectAnswers = beforeCorrect
                    setIncorrectAnswersWaiting(afterCorrect)
                } else {
                    setIncorrectAnswersWaiting([])
                }
                // correctAnswerPlacement === incorrectAnswersLoaded)
                setQuestions(questions => {
                    const updatedQuestions2 = [...questions]
                    if (updatedQuestions2[questionIndex] === undefined) {
                        updatedQuestions2[questionIndex] = {}
                    }
                    const { correctAnswer } = updatedQuestions2[questionIndex]
                    updatedQuestions2[questionIndex] =
                    {
                        type,
                        question_text: questionText,
                        incorrectAnswers,
                        correctAnswer: correctAnswer,
                        correctAnswerWaiting
                    }
                    return updatedQuestions2
                }
                )
                // setQuestions(updatedQuestions)
            }
        } catch (e) {
            console.warn(e, stream)
        }
    }, [stream])

    // todo this seems terrible coding practice
    useEffect(() => {
        props.questionLoading(loading)
    }, [loading])

    useEffect(() => {
        if (!correctAnswerDisplayed) return
        if (incorrectAnswersWaiting) {
            setQuestions(questions => {
                const updatedQuestions = [...questions]
                const { type, question_text, correctAnswer, incorrectAnswers } = updatedQuestions[questionIndex]
                updatedQuestions[questionIndex] =
                {
                    type,
                    question_text,
                    incorrectAnswers: incorrectAnswers.concat(incorrectAnswersWaiting),
                    correctAnswer
                }
                return updatedQuestions
            }
            )
        }

    }, [correctAnswerDisplayed])

    useEffect(() => {
        props.questionToggle("start")
    }, [currentQuestion])

    let count = 0;
    const speed = 100;

    useEffect(() => {
        if (!showCorrectAnswer) return
        const words = correctAnswerWaiting.split(" ")

        const interval = setInterval(() => {
            if (count === words.length) {
                setCorrectAnswerDisplayed(true)
                clearInterval(interval);
                return;
            }

            setQuestions(questions => {
                const updatedQuestions = [...questions]
                const prevText = updatedQuestions[questionIndex]['correctAnswer']
                const { type, question_text, correctAnswer, incorrectAnswers } = updatedQuestions[questionIndex]
                updatedQuestions[questionIndex] =
                {
                    type,
                    question_text,
                    incorrectAnswers,
                    correctAnswer: (prevText === undefined ? "" : prevText) + " " + words[count]
                }
                return updatedQuestions
            }
            )
            count++;
        }, speed);


    }, [showCorrectAnswer])

    useEffect(() => {
        if (props.random) {
            const {id, randomTopic} = randomLeaf(tree)
            getAQuestion(randomTopic)
            setActualTopic(randomTopic)
            setActualTopicId(id)
        } else {
            setTopic(props.topic)
            getAQuestion(props.topic)
        }
        setStartLessonTime(Date.now())
    }, [props.topic])

    const getAQuestion = async (topic) => {

        // randomly select one of "multiple_choice", "two_blanks"
        // const type = ["multiple_choice", "two_blanks"][Math.floor(Math.random() * 2)]
        const type = "multiple_choice"

        const typeNames = {
            "multiple_choice": "Multiple Choice",
            "two_blanks": "Fill in the Blank"
        }

        const types = {
            "multiple_choice": `
                Fill in the following json object by replacing the x's with an actual question, 
                the correct answer, and ${numOfMultipleChoiceOptions - 1} plausible but incorrect answers. 
                Do not provide any text outside of the json.
                Do not provide any keys other than the ones specified.
                Be sure that the json is correctly formatted, with the correct number of commas, brackets, and quotes. 
                It should match this format: 
                {"type": "multipleChoice"},
                {"questionText": "What is the capital of France?"},
                {"correctAnswer": "Paris"},
                {"incorrectAnswers": ["London", "New York", "Berlin"]}
                `,
            "one_blank": "create a true statement, but remove two of its word, and replace it with 'x'",
            "two_blanks":
                `To do so, generate a "fill in the blank" type exam problem, with one or two blanks (denoted by ___), providing the correct answers and 4 plausible but incorrect answers for each blank.
                The problem should be a true statement (not a question), but with one or two words removed and replaced with ___.`

            // `
            // Create a fill-in-the blank type question. 
            // Pretend to be an expert on the topic and generate a true statement about it that demonstrates your knowledge, saving it in original_statement. 
            // Then convert that statement into a double fill-in-the-blank problem by replacing a significant word 
            // with ___ and saving that word in correctAnswers.first_blank, and then again replacing a different significant word 
            // with ___ and saving that word in correctAnswers.second_blank. 
            // Then fill out the answers arrays with for each blank with 3 incorrect but plausible answers. 
            // Provide back the answer options, the correct answers, and the statement with and without the words missing.`
        }

        const formats = {
            "two_blanks": {
                "type": "two_blanks",
                "questionText": "...",
                "incorrectAnswers": {
                    "first_blank": ["x", "x", "x", "x"],
                    "second_blank": ["y", "y", "y", "y"]
                },
                "correctAnswers": {
                    "first_blank": "x",
                    "second_blank": "y"
                }
            },
            "multiple_choice": `
                {"type": "multiple_choice"},
                {"question_text": "x"},
                {"correctAnswer": "x"},
                {"incorrectAnswers": ["x", "x", "x"]},
            `
        }

        let prompt3;
        if (props.random || previousQuestions.length === 0) {
            prompt3 = 
        `
        {
            'our goal': 'We are trying to test how well a person knows "${topic}". We want to ask a question for each major subtopic and concept and historical period of "${topic}"',
            'your job': 'Create the first question.',
            'exected output': 'Generate a ${typeNames[type]} exam problem on the topic of ${topic} that would
            be extremelty difficult for a ${difficultyLevels[levelRequested - 1]}. ${types[type]}'
        `
        } else {
            const prevoiusQuestionsText = previousQuestions.map(q => q.question.question_text).join("\n")
            console.log("prevoiusQuestionsText", prevoiusQuestionsText)
            prompt3 = `
            {
                'our goal': 'We are trying to test how well a person knows "${topic}". We want to ask a question for each major subtopic and concept and historical period of "${topic}"',
                'your job': 'Create the next question. It should cover a topic that has not been asked already.',
                'previous questions': '${prevoiusQuestionsText}',
                'expected output': 'Generate a ${typeNames[type]} exam problem on the topic of ${topic} that would
                be extremelty difficult for a ${difficultyLevels[levelRequested - 1]}. ${types[type]}'
            }
        `
        }
        console.log("prompt3", prompt3)

        const stream = true
        const formatString = formats[type]
        if (stream) {
            const reader = await completeWithSystemStream(prompt3, "")
            while (true) {
                const { value, done } = await reader.read();
                if (done) break; // does not work
                const dt = value.split("data: ")


                const newContent = dt
                    .filter(i => i !== "" && i !== "[DONE]\n\n")
                    .map(i => JSON.parse(i))
                    .filter(i => i.choices[0].delta.content !== undefined)
                    .map(i => i.choices[0].delta.content)
                    .join("")
                setStreamChunk(newContent)

                const lastData = dt[dt.length - 1]
                if (lastData === "[DONE]\n\n") {
                    // setQuestionIndex(questionIndex + 1)
                    setStreamChunk(lastData)
                    break
                }

                // const data2 = JSON.parse(data)
                // setStreamChunk(data2.choices[0].delta.content)
            }
        } else {
            const chunk = await completeWithSystem(prompt3, `return the following json array back, with the keys filled in with real data for the generated question: ${formatString}`)
            setStreamChunk(chunk)
        }



    }

    const handleAnswerClick = async (questionIndex, answerIndex, isCorrect) => {
        const updatedSelected = { ...selected };
        updatedSelected[questionIndex] = answerIndex;
        setSelected(updatedSelected);

        setPastQAResults([...pastQAResults, isCorrect ? 1 : -1])
        const topDomain = domainKeyToPathKeys(actualTopicId)[0]

        let newTopDomainScores = topDomainScores
        newTopDomainScores[topDomain-1] = topDomainScores[topDomain-1] + (isCorrect ? 1 : 0)
        setTopDomainScores(newTopDomainScores)
    



        dispatch(addAnsweredQuestion({ question: questions[currentQuestion], isCorrect }))

        setLevelRequested(l => {
            console.log(l)
            let newLevelRequested = l + (isCorrect ? 1 : -1)
            if (newLevelRequested === 0) newLevelRequested = 1
            if (newLevelRequested === 17) newLevelRequested = 16
            return newLevelRequested
        })
        // const updatedQScore = { ...questionScore }
        // updatedQScore[questionIndex] = isCorrect ? 1 : -1
        // setQuestionScore(updatedQScore)

        // const content = "the question: " + questions[questionIndex].question_text + "the answer: " + questions[questionIndex].correctAnswer
        // const domains = flatten(topNLevels(tree, 3)).join("\n")
        // const prompt = getPrompt(content, domains)


        // // // get the next question
        // // const reader = await completeWithSystem2Stream(prompt, "")
        // // while (true) {
        // //     const {value, done} = await reader.read();
        // //     if (done) break;
        // //     const dt = value.split("data: ")
        // //     const data = dt[dt.length - 1]
        // //     const data2 = JSON.parse(data)
        // //     setStreamChunk(data2.choices[0].delta.content)
        // //   }

        // // do some other stuff
        // const resp = await completeWithSystem2Stream(prompt, "")
        // const respJSON = JSON.parse(matchRecursive(resp, '{...}'))

        // const domainKey = respJSON.domains[0].split("-")[0].trim()

        // const subtree = topNLevels(domainKeyToSubTree(tree, domainKey), 1)

        // const domains2 = flatten(subtree).join("\n")

        // const prompt2 = getPrompt(content, domains2)
        // const resp2 = await completeWithSystem2Stream(prompt2, "")
        // const resp2JSON = JSON.parse(matchRecursive(resp2, '{...}'))

        // const updatedDomainScore = { ...domainScore }
        // // for each domain, if the domain is in the response, add 1 to the score
        // for (let i = 0; i < resp2JSON.domains.length; i++) {
        //     const domain = resp2JSON.domains[i]
        //     if (updatedDomainScore[domain] === undefined) {
        //         updatedDomainScore[domain] = isCorrect ? 1 : -1
        //     } else {
        //         updatedDomainScore[domain] += isCorrect ? 1 : -1
        //     }
        // }
        // setDomainScore(updatedDomainScore)
    };

    const handleAnswerClickDouble = (questionIndex, answerIndex, row, isCorrect) => {
        const updatedSelected = { ...selected };
        if (updatedSelected[questionIndex] === undefined) {
            updatedSelected[questionIndex] = {};
        }
        updatedSelected[questionIndex][row] = answerIndex;
        setSelected(updatedSelected);
        const updatedQScore = { ...questionScore }
        if (updatedQScore[questionIndex] === undefined) {
            updatedQScore[questionIndex] = isCorrect ? 1 : -1
        } else {
            updatedQScore[questionIndex] = (updatedQScore[questionIndex] + isCorrect ? 1 : -1) / 2
        }
        setQuestionScore(updatedQScore)
    };

    // when new questions come in, display it if we're waiting for it
    // useEffect(() => {
    //     if (currentQuestion < requestedQuestion) {
    //         try {
    //             const question = questions[requestedQuestion].question_text
    //             setCurrentQuestion(requestedQuestion);
    //             props.nextQuestion(nextDiff)
    //             setLoading(false);
    //         } catch (e) {
    //             console.log('question not ready: ', requestedQuestion)
    //         }
    //     }
    // }, [questions])

    const loadNextTopics = async () => {
        const prompt = `
        {
            'our goal': 'Generate 3 topics for the next lesson, based on the current lesson. One should be a subtopic of the current lesson, one should be a sibling topic, and one should be unrelated topic.',
            'rules': '
                - each topic should be 1 to 3 words, no more.
                - Do not provide any text outside of the json.
                - Be sure that the json is correctly formatted, with the correct number of commas, brackets, and quotes.',
            'current topic': '${topic}',
            'expected output': 'return the following json, with the xs replaced with the topics: {"subtopic": "x", "sibling": "x", "unrelated": "x"}'
        }
        `
        const resp = await getNextTopics(prompt)
        const topics = resp.replace(/^[^{}]*(?=\{)/, "")
        console.log(topics)
        const match = jsonrepair(topics)
        console.log(match)
        const parsed = JSON.parse(match)
        const {subtopic, sibling, unrelated} = parsed
        console.log(parsed)
       
        setNextTopics({ subtopic, sibling, unrelated})
    }

    // unless we're already waiting for the next question,
    // then pass the score to the parent 
    // and start the sequence to move to the next question
    const handleNextQuestionClick = (diff) => {
        const lessonLength = 7
        setDescription("")
        setDisplayExplanation(false)

        // we're almost done, start loading data for the next lesson options
        if (questionIndex + 1 >= lessonLength - 3 && !loadingNextTopics) {
            setLoadingNextTopics(true)
            loadNextTopics()
        }
        // +1 because zero-indexing
        if (questionIndex + 1 >= lessonLength) {
            // alert('lesson complete!')
            // count the number of correct and incorrect answers
            const correct = pastQAResults.filter(x => x === 1).length
            const incorrect = pastQAResults.filter(x => x === -1).length
            console.log(correct, incorrect)

            // time taken to complete lesson
            const timeTaken = Date.now() - startLessonTime
            console.log(timeTaken)
            setLessonCompleteTime(Date.now())
            setLessonComplete(true)
        } else {
            setCurrentQuestion(currentQuestion + 1);
            setLevelRequested(levelRequested + diff)
            setStream("")

            setCorrectAnswerWaiting("")
            setCorrectAnswerPlacement(-1)
            setShowCorrectAnswer(false)
            setCorrectAnswerDisplayed(false)
            setIncorrectAnswersWaiting([])
            setQuestionIndex(questionIndex + 1)

            if (props.random) {
                const {id, randomTopic} = randomLeaf(tree)
                getAQuestion(randomTopic, questions)
                setActualTopic(randomTopic)
                setActualTopicId(id)
            } else {
                getAQuestion(topic, questions)
            }
        }

        

        // if (loading) return
        // else {
        //     props.questionToggle("end", questionScore[currentQuestion], domainScore)
        // }
        // try {
        //     getAQuestion()


        //     const question = questions[currentQuestion + 1].question_text

        //     props.nextQuestion(diff)
        // } catch (e) {
        //     setNextDiff(diff)
        //     console.log('question not ready: ', currentQuestion + 1)
        //     setRequestedQuestion(currentQuestion + 1);
        //     setLoading(true);
        // }
    };

    const newTopic = (topic) => {
        dispatch(reset())
        setCurrentQuestion(0)
        setRequestedQuestion(0)
        setSelected({})
        setLoading(false)
        setNextDiff(1)
        setQuestionScore({})
        setDomainScore({})
        setQuestions([])
        setQuestionIndex(0)
        setStreamChunk("")
        setStream("")
        setLevelRequested(1)
        setLoadingCorrectAnswerStartTime(0)
        setLoadingCorrectAnswerStopTime(0)
        setStartLessonTime(Date.now())
        setLessonCompleteTime(0)
        setCorrectAnswerWaiting("")
        setCorrectAnswerPlacement(-1)
        setShowCorrectAnswer(false)
        setCorrectAnswerDisplayed(false)
        setIncorrectAnswersWaiting([])
        setPastQAResults([])
        setTopDomainScores([0,0,0,0,0,0])
        setActualTopic("")
        setActualTopicId("")
        setLessonComplete(false)
        setNextTopics({})
        setLoadingNextTopics(false)
        setTopic(topic)
        getAQuestion(topic)
    }

    const renderQuestion = () => {
        switch (questions[currentQuestion].type) {
            case 'multipleChoice':
                return (
                    <MultipleChoiceQuestion
                        question={questions[currentQuestion]}
                        correctAnswerPlacement={correctAnswerPlacement}
                        currentSelected={selected[currentQuestion]}
                        handleAnswerClick={(answer, correct) => handleAnswerClick(currentQuestion, answer, correct)}
                    />
                );
            case 'two_blanks':
                return (
                    <DoubleBlankQuestion
                        question={questions[currentQuestion]}
                        currentSelected={selected[currentQuestion]}
                        handleAnswerClick={(answer, row, correct) => handleAnswerClickDouble(currentQuestion, answer, row, correct)}
                    />
                );
            case 'one_blank':
                return (
                    <MultipleChoiceQuestion
                        question={questions[currentQuestion]}
                        currentSelected={selected[currentQuestion]}
                        handleAnswerClick={(answer, correct) => handleAnswerClick(currentQuestion, answer, correct)}
                    />
                );
            default:
                console.log("null type")
                return null;
        }
    };



    const currentQuestionData = questions[currentQuestion];
    console.log(currentQuestionData, currentQuestionData?.question_text)
    
    if (currentQuestionData === undefined || !currentQuestionData.question_text) return (
        <div>
            <Typography sx={{color: '#dfb774'}} variant="h4" gutterBottom className={classes.topic}>{capitalizeFirstLetter(topic)}</Typography>
            <ScoreBar pastQAResults={pastQAResults}/>

            <Box sx={{ textAlign: 'center' }}><CircularProgress size={20} sx={{ margin: '80px', color: 'white' }} />
            </Box>
        </div>)
    const currentAnswers = currentQuestionData.answers;
    const explanation = currentQuestionData.explanation || "";
    const answered = selected[currentQuestion] !== undefined;



    let domainPath;
    let domainColor;
    if (actualTopicId) {
        domainPath = domainKeyToPathKeys(actualTopicId).filter(i => i.split('.').length === 3).map(i => domainKeyToName(tree, i)).join(" > ")
        domainColor = ['#7780ff', '#ed7dae', '#ff00a0', '#ff006e', '#ff4341', '#ff910d'][domainKeyToPathKeys(actualTopicId)[0] - 1]
    }
    
    console.log(pastQAResults)
    const correct = pastQAResults.filter(x => x === 1).length
    const incorrect = pastQAResults.filter(x => x === -1).length
    const percentCorrect = Math.round(correct / (correct + incorrect) * 100)

    const { subtopic, sibling, unrelated } = nextTopics


    const toggleExplanation = () => {
        setDisplayExplanation(!displayExplanation)
    }

    return (
        <div>
            <Typography sx={{color: '#dfb774'}} variant="h4" gutterBottom className={classes.topic}>{capitalizeFirstLetter(topic)}</Typography>
            <ScoreBar pastQAResults={pastQAResults}/>
            
            {!lessonComplete ? (
                <Paper className={classes.paper}>
                    <Typography sx={{color: domainColor}} variant="h6" gutterBottom className={classes.topic}>{domainPath}</Typography>
                    <Typography variant="h6" gutterBottom className={classes.question}>
                        {currentQuestionData.question_text}
                    </Typography>
                    {/* {!displayExplanation && */}
                    {renderQuestion()}
{/* } */}

{answered && 
                    <Box className={classes.buttonContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ margin: '10px', padding: '12px 25px' }}
                            onClick={() => toggleExplanation()}
                            endIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null}>
                            { !displayExplanation && 'explain'}
                            { displayExplanation && 'ok I got it'}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ margin: '10px', padding: '12px 25px' }}
                            onClick={() => handleNextQuestionClick(1)}
                            endIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null}>
                            {'Next Question'}
                        </Button>
                    </Box>
}
                    {displayExplanation &&
                    <div className={classes.explanation}>
                    <Typography variant="h6" gutterBottom className={classes.question}>
                        {explanation}
                    </Typography>
                    </div>
}
                </Paper>
            ) : (
                <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>
                        Lesson Complete!
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Accuracy: {percentCorrect}%
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Time: {Math.round((lessonCompleteTime - startLessonTime) / 1000)} seconds
                    </Typography>
                    <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        Let's keep going with the next topic: 
                    </Typography>
                    <Button
                            variant="contained"
                            color="primary"
                            sx={{ margin: '10px', padding: '12px 25px' }}
                            onClick={() => newTopic(subtopic)}
                            >
                            {subtopic}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ margin: '10px', padding: '12px 25px' }}
                            onClick={() => newTopic(sibling)}
                            // onClick={}
                            >
                            {sibling}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ margin: '10px', padding: '12px 25px' }}
                            onClick={() => newTopic(unrelated)}
                            // onClick={}
                            >
                            {unrelated}
                        </Button>
                    </Box>
                </Paper>
            )}
        </div>
    );
}