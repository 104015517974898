import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import { answeredQuestionsSlice } from './answeredQuestions'
import { domainScoreSlice } from './domainScore'
import { authSlice } from './auth'
import * as tree from '../scripts/tree.json';
import { flatten, topNLevels, domainKeyToSubTree } from '../components/Questions/util';
import { completeWithSystem2 } from '../api/openai';
import { QuestionAnswer } from '../models';
import { DataStore } from 'aws-amplify';



const getDomainScorePrompt = (question, domains) => {
    const prompt = `
        You should provide back
        - the domains that the question falls under, e.g. "2.1.1.1 - Neuroscience"
        - an explanation of why you chose those domains

        ${question}

        Domains:
        ${domains}

        return the following JSON object back, with the keys filled in with your response. 
        return nothing else other than the JSON object.
        every question will fall into at least one domain.

        { "domains": ["x"], "explanation": "x" }
    `
    return prompt
}

const listenerMiddleware = createListenerMiddleware()

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.

listenerMiddleware.startListening({
    actionCreator: answeredQuestionsSlice.actions.addAnsweredQuestion,
    effect: async (action, listenerApi) => {
        const { question, isCorrect } = action.payload

        const content = "the question: " + question.question_text + "the answer: " + question.correctAnswer
        const domains = flatten(topNLevels(tree, 3)).join("\n")
        const prompt = getDomainScorePrompt(content, domains)

        const resp = await completeWithSystem2(prompt, "")
        const respJSON = JSON.parse(resp)
        // const domainKey = respJSON.domains[0].split("-")[0].trim()

        // const subtree = topNLevels(domainKeyToSubTree(tree, domainKey), 1)
        // const domains2 = flatten(subtree).join("\n")
        // const prompt2 = getDomainScorePrompt(content, domains2)
        // const resp2 = await completeWithSystem2(prompt2, "")
        // const resp2JSON = JSON.parse(resp2)

        const domainsMeasured = respJSON.domains

        await DataStore.save(
            new QuestionAnswer({
                type: question.type,
                questionText: question.question_text,
                userEmail: listenerApi.getState().authEmail.value,
                correctAnswer: question.correctAnswer,
                incorrectAnswers: question.incorrectAnswers,
                requestedTopic: question.requestedTopic,
                measuredTopics: domainsMeasured,
                wasCorrect: isCorrect,
                timestamp: new Date().toISOString(),
            })
        );

        const updatedDomainScore = { }
        // // for each domain, if the domain is in the response, add 1 to the score
        for (let i = 0; i < domainsMeasured.length; i++) {
            const domain = domainsMeasured[i].split("-")[0].trim()
            if (updatedDomainScore[domain] === undefined) {
                updatedDomainScore[domain] = isCorrect ? 1 : -1
            } else {
                updatedDomainScore[domain] += isCorrect ? 1 : -1
            }
        }

        listenerApi.dispatch(domainScoreSlice.actions.addDomainScore({domains: updatedDomainScore}))
        // setDomainScore(updatedDomainScore)



        // const providedInfo = listenerApi.getState().questionContext.value.missingInfoCollected
        // const providedInfoString = JSON.stringify(providedInfo)
        // const aiAnswer = await query({prompt: `Generate a comprehensive and informative answer (no more than 80 words) for a given question. Cite relevant documents using [$(number)] notation. The first citation for each answer should be numbered 1. \n\nquestion\n===\nwho is the president?\n\nanswer\n===\nThe current President of the United States of America is Joe Biden [1]. He was inaugurated on January 20, 2021. [2] \n\n[1] https://www.whitehouse.gov/about-the-white-house/the-president/\n[2] https://www.npr.org/2021/01/20/958509320/in-his-inaugural-address-joe-biden-pledges-unity-and-action\n\nquestion\n===\n${question}\ncontext:${providedInfoString}\n\nanswer\n===`})
        // listenerApi.dispatch(conversationHistory.actions.addBotResponse(aiAnswer))
        // listenerApi.dispatch(botStateSlice.actions.questionAnswered())
    }
})

const store = configureStore({
    reducer: {
        answeredQuestions: answeredQuestionsSlice.reducer,
        domainScore: domainScoreSlice.reducer,
        authEmail: authSlice.reducer,
    },
  // Add the listener middleware to the store.
  // NOTE: Since this can receive actions with functions inside,
  // it should go before the serializability check middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
})

export default store;