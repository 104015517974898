import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  timer: {
    fontFamily: 'Press Start 2P, cursive',
    fontSize: '24px',
    color: '#00ff00',
    backgroundColor: '#000000',
    padding: '10px',
    border: '1px solid #00ff00',
    display: 'inline-block',
  },
});

const Score = ({ score }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.timer}>
      {score} points
    </div>
  );
};

export default Score;
