import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ProveHomePage from './components/ProveHomePage';
import LearningHistoryPage from './components/LearningHistoryPage';
import { useDispatch } from 'react-redux'
import { authSlice } from './store';


const { addAuth } = authSlice.actions


const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#303030',
    },
    secondary: {
      main: '#000000',
    },
    action: {
      active: '#1E90FF',
    },
  },
});

function App({signout, user}) {
  const dispatch = useDispatch()
  dispatch(addAuth({ email: user.attributes.email })) 
  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<ProveHomePage />} />
        <Route path="/history/:type" element={<LearningHistoryPage/>} />
        <Route path="/history" element={<LearningHistoryPage/>} />
        <Route path="/foryou" element={<ProveHomePage random/>} />
        <Route path="/shuffle" element={<ProveHomePage random/>} />
        <Route path="/search/:topic" element={<ProveHomePage/>} />
        <Route path="/search" element={<ProveHomePage/>} />  
        <Route path="*" element={<Navigate to='/'/>} />
         
      </Routes>
    </ThemeProvider> 
  );
}

export default App;