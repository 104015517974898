import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Box} from '@mui/material';
import './test.scss';

// given a tree of knowledge domains, return the top n levels of the tree
// where the tree is stored like this:
// {
//     "1": {
//         "name": "Natural Sciences",
//         "1.1": {
//             "name": "Physics",
//             "1.1.1": {


const useStyles = makeStyles((theme) => ({
    answerBox: {
        border: '1px solid gray',
        padding: theme.spacing(2),
        margin: theme.spacing(1),
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        textAlign: 'left',
        backgroundColor: '#333',
        borderRadius: '3px',
        '&:hover': {
            backgroundColor: '#444',
        }
    },
    correctAnswerBox: {
        backgroundColor: '#dfb774', 
        color: 'white',
        '&:hover': {
            backgroundColor: '#dfb774',
        }
    },
    incorrectAnswerBox: {
        backgroundColor: 'gray', //c23
        color: 'white',
        '&:hover': {
            backgroundColor: 'gray',
        }
    }
}));

function fillArray(array, length) {
    if (array.length >= length) {
      return array; // No need to add additional values
    }
  
    const additionalValues = length - array.length;
    for (let i = 0; i < additionalValues; i++) {
      array.push(""); // Add empty values to the array
    }
  
    return array;
  }

  function max3(arr) {
    if (arr.length <= 3) {
      return arr;
    } else {
      return arr.slice(0, 3);
    }
  }

export const MultipleChoiceQuestion = (props) => {
    const classes = useStyles();
    
    const handleAnswerClick = props.handleAnswerClick
    const currentQuestionData = props.question
    const correctAtEnd = true
    const currentAnswers = currentQuestionData.incorrectAnswers === undefined ? [] : max3([...currentQuestionData.incorrectAnswers])
    const element = currentQuestionData.correctAnswer === undefined ? '' : currentQuestionData.correctAnswer
    if (element !== '') currentAnswers.splice(props.correctAnswerPlacement, 0, element);
    fillArray(currentAnswers, 4)
    // switch (props.correctAnswerPlacement) {
    //     case 0:

    // }
    // if (correctAtEnd) {
    //     currentAnswers = currentQuestionData.incorrectAnswers === undefined ? [] : [...currentQuestionData.incorrectAnswers]
    //     if (currentQuestionData.correctAnswer !== undefined) {
    //         currentAnswers.push(currentQuestionData.correctAnswer)
    //     }
    // } else {
    //     currentAnswers = currentQuestionData.correctAnswer === undefined ? [] : [currentQuestionData.correctAnswer]
    //     if (currentQuestionData.incorrectAnswers !== undefined) {
    //         currentAnswers.push(...currentQuestionData.incorrectAnswers)
    //     }
    // }
    
    const currentSelected = props.currentSelected;
    const isCorrect = currentSelected !== undefined && currentAnswers[currentSelected] === currentQuestionData.correctAnswer;
    const hasAnswered = currentSelected !== undefined;

    const answerClick = (answerIndex, isCorrectAnswer) => {
        if (hasAnswered) {
            return;
        } else {
            handleAnswerClick(answerIndex, isCorrectAnswer)
        }
    }

    return (
        <Grid container spacing={2}>
            {currentAnswers !== undefined && currentAnswers.map((answer, answerIndex) => {
                const isCorrectAnswer = currentQuestionData.correctAnswer === answer;
                const isSelectedAnswer = currentSelected === answerIndex;

                let answerBoxClass = classes.answerBox;
                if (hasAnswered) {
                    if (isSelectedAnswer && isCorrect) {
                        answerBoxClass += ` ${classes.correctAnswerBox}`;
                    } else if (isSelectedAnswer && !isCorrect) {
                        answerBoxClass += ` ${classes.incorrectAnswerBox}`;
                    } else if (!isSelectedAnswer && isCorrectAnswer) {
                        answerBoxClass += ` ${classes.correctAnswerBox}`;
                    }
                }

                return (
                    <Grid key={answerIndex} item xs={6} sm={3}>
                        <Box
                            className={answerBoxClass}
                            onClick={() => answerClick(answerIndex, isCorrectAnswer)}
                        >
                            {answer}
                        </Box>
                    </Grid>
                );
            })}
        </Grid>
    )
}