const DEFAULT_PARAMS = {
    "model": "gpt-3.5-turbo",
    "temperature": 0.7,
    // "max_tokens": 256,
    // "top_p": 1,
    // "frequency_penalty": 0,
    // "presence_penalty": 0
}

const prompt = (content, filter) => {
    const PROMPTS = {
        "summary": "generate a one-sentence summary, no more than 15 words, of the following message: \n\n",
        "critic": "generate a mocking summary, no more than 15 words, of the following message: \n\n",
        "reddit": "generate a reddit-style title for the following message: \n\n",
        "praise": "generate a praiseful title, no more than 15 words, for the following message: \n\n"
    }

    const PROMPTS_END = {
        "summary": "\n\nSummary:",
        "critic": "\n\nSummary: ",
        "reddit": "\n\nTitle: ",
        "praise": "\n\nTitle: "
    }

    return PROMPTS[filter] + content + PROMPTS_END[filter]
}

export async function autoCorrectWithContext(word, context) {
    if (context.length === 0) {
        return complete(`
    What is the correct spelling of the word "${word}"?. Please return only the word, no commentary, and in the original capitalization.
    `)
    } else {
        const contextWords = context.join(" ")
        return complete(`
    Return the correct spelling of "${word}"? It is found in this context: "${contextWords} ${word}"? 
    Please return only the word with no additional commentary.
    Do not make any changes to capitalization.
    `)
    }
}

export async function autoCorrect(content) {
    return complete(`
    What is the correct spelling of the following word? Please return only the word, no commentary, and in the same capitalization. 
    If there are multiple correct spellings, return the most common one.
    If you can't find a correct spelling, then return the input unchanged. 
    If the word is spelled correctly, return the input unchanged, with no commentary, and no other words:\n\n` + 
    content)
}

export async function autoCorrectBody(content) {
    const corrected = await complete(`Return the input with spelling corrected:` + 
    content)
    return corrected.replace(/^"(.+(?="$))"$/, '$1');
}
    

export async function query(system, history) {
    const messages = [{"role": "system", "content": system}, {"role": "user", "content": history}]
    const params_ = { ...DEFAULT_PARAMS, ...{messages} };
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        },
        body: JSON.stringify(params_)
    };

    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);
    const data = await response.json();
    // return data.choices[0].text;
    return data.choices[0].message.content
}

export async function getNextTopics(prompt) {
    const messages = [{"role": "system", "content": "You are a helpful and intelligent assistant that creates exam topics, following the instructions precisely."}, {"role": "user", "content": prompt}]
    const params_ = { ...DEFAULT_PARAMS, ...{messages} };
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        },
        body: JSON.stringify(params_)
    };

    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);
    const data = await response.json();
    // return data.choices[0].text;
    const ret = data.choices[0].message.content
    return ret
}

export async function completeWithSystem(prompt, content) {
    const messages = [{"role": "system", "content": "You are an educated and intelligent assistant that creates in-depth and nuanced exam questions. Reach deep into your knowledge to create verbose questions that require a deep understanding to answer."}, {"role": "user", "content": prompt + "\n" + content}]
    const params_ = { ...DEFAULT_PARAMS, ...{messages} };
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        },
        body: JSON.stringify(params_)
    };

    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);
    const data = await response.json();
    // return data.choices[0].text;
    const ret = data.choices[0].message.content
    return ret
}

export async function getExplanationStream(prompt) {
    const messages = [{"role": "system", "content": "You are an educated and intelligent assistant that explains exam questions"}, {"role": "user", "content": prompt}]
    const params_ = { ...DEFAULT_PARAMS, ...{messages}, stream: true };
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        },
        body: JSON.stringify(params_)
    };

    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);

    // eslint-disable-next-line no-undef
    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

    return reader
}

export async function completeWithSystemStream(prompt, content) {
    const messages = [{"role": "system", "content": "You are an educated and intelligent assistant that creates in-depth and nuanced exam questions. Reach deep into your knowledge to create verbose questions that require a deep understanding to answer."}, {"role": "user", "content": prompt + "\n" + content}]
    const params_ = { ...DEFAULT_PARAMS, ...{messages}, stream: true };
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        },
        body: JSON.stringify(params_)
    };

    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);

    // eslint-disable-next-line no-undef
    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

    return reader
}

export async function completeWithSystem2(prompt, content) {
    const messages = [{"role": "system", "content": "You categorize questions into relevant domains. Be specific as possible and only use the domains provided."}, {"role": "user", "content": prompt + "\n" + content}]
    const params_ = { ...DEFAULT_PARAMS, ...{messages} };
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        },
        body: JSON.stringify(params_)
    };

    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);
    const data = await response.json();
    // return data.choices[0].text;
    const ret = data.choices[0].message.content
    return ret
}

export async function completeWithSystem2Stream(prompt, content) {
    const messages = [{"role": "system", "content": "You categorize questions into relevant domains. Be specific as possible and only use the domains provided."}, {"role": "user", "content": prompt + "\n" + content}]
    const params_ = { ...DEFAULT_PARAMS, ...{messages}, stream: true};
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        },
        body: JSON.stringify(params_)
    };

    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);
    // eslint-disable-next-line no-undef
    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
    

    return reader
}

export async function completeWithSystemAndHistory(prompt, content, chatHistory) {
    const messages = [{"role": "system", "content": prompt}, ...chatHistory, {"role": "user", "content": content}]
    const params_ = { ...DEFAULT_PARAMS, ...{messages} };
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        },
        body: JSON.stringify(params_)
    };

    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);
    const data = await response.json();
    // return data.choices[0].text;
    return data.choices[0].message.content
}
export async function complete(prompt) {
    const messages = [{"role": "user", "content": prompt}]
    const params_ = { ...DEFAULT_PARAMS, ...{messages} };
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        },
        body: JSON.stringify(params_)
    };

    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);
    const data = await response.json();
    // return data.choices[0].text;
    return data.choices[0].message.content
}

const completionCall = async (content) => {
    const messages = [{"role": "user", "content": content}]
    const params_ = { ...DEFAULT_PARAMS, ...{messages} };
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        },
        body: JSON.stringify(params_)
    };

    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);
    const data = await response.json();
    return data.choices[0].message.content
}

export const generateTitle = async (style, message) => {
    if (style === 'raw') {
        return message
    }
    const content = prompt(message, style)
    const resp = await completionCall(content)
    return resp.replace(/^"(.+(?="$))"$/, '$1');
}


export const getEmbedding = async (input) => {
    const params_ = { 'model': 'text-embedding-ada-002', input}
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
        },
        body: JSON.stringify(params_)
    };

    const response = await fetch('https://api.openai.com/v1/embeddings', requestOptions);
    const data = await response.json();
    return data.data[0].embedding
}

