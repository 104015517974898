const matchRecursiveInner = () => {
    var formatParts = /^([\S\s]+?)\.\.\.([\S\s]+)/,
        metaChar = /[-[\]{}()*+?.\\^$|,]/g,
        escape = function (str) {
            return str.replace(metaChar, "\\$&");
        };

    return function (str, format) {
        var p = formatParts.exec(format);
        if (!p) throw new Error("format must include start and end tokens separated by '...'");
        if (p[1] == p[2]) throw new Error("start and end format tokens cannot be identical");

        var opener = p[1],
            closer = p[2],
            /* Use an optimized regex when opener and closer are one character each */
            iterator = new RegExp(format.length == 5 ? "[" + escape(opener + closer) + "]" : escape(opener) + "|" + escape(closer), "g"),
            results = [],
            openTokens, matchStartIndex, match;

        do {
            openTokens = 0;
            while (match = iterator.exec(str)) {
                if (match[0] == opener) {
                    if (!openTokens)
                        matchStartIndex = iterator.lastIndex;
                    openTokens++;
                } else if (openTokens) {
                    openTokens--;
                    if (!openTokens)
                        results.push(str.slice(matchStartIndex, match.index));
                }
            }
        } while (openTokens && (iterator.lastIndex = matchStartIndex));

        return "{" + results + "}";
    };
}

export const matchRecursive = matchRecursiveInner();

export const randomLeaf = (tree, parentId) => {
    const keys = Object.keys(tree).filter(i => i !== 'name').filter(i => i !== '3' && i !== '4' && i !== '5' && i !== '6' && i !== '7' && i !== '8')
    if (keys.length === 0) return {id: parentId, randomTopic: tree['name']}

    const randomKey = keys[Math.floor(Math.random() * keys.length)]
    return randomLeaf(tree[randomKey], randomKey)
}