import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import {
  useParams,
  Link,
  matchPath,
  useLocation
} from "react-router-dom";
import { DataStore } from 'aws-amplify';
import { QuestionAnswer } from '../models';
import { Box, Typography, Paper, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TopBar } from './TopBar';
import { LevelHistory } from './LevelHistory';
import { XPHistory } from './XPHistory'; 


const useStyles = makeStyles({
  paper: {
    padding: '20px',
    margin: '20px',
  },
  paperCorrect: {
    borderLeft: '1px solid green'
  },
  paperInCorrect: {
    borderLeft: '1px solid red'
  },
  correct: {
    color: 'green',
  },
  incorrect: {
    color: 'red',
  },
  chartContainer: {
    maxHeight: '85vh',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 50px 10px',
    '@media (max-width: 768px)': {
      padding: '20px 0 0 0',
    },
  },
});

// put this in a shared place
function useRouteMatch(patterns) {
  const { pathname } = useLocation();
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }
  return null;
}

function HistoryTypeSelector() {
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  const routeMatch = useRouteMatch(['/history/level', '/history/xp']);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Tabs value={currentTab} centered sx={{ marginBottom: '15px' }}>
      <Tab label="Level" value='/history/level' to='/history/level' component={Link} />
      <Tab label="Exp" value='/history/xp' to='/history/xp' component={Link} />
    </Tabs>
  );
}

export default function LearningHistoryPage() {
  const classes = useStyles();

  const { domain: domainParam, type } = useParams();
  const userEmail = useSelector((state) => state.authEmail.value)

  const [pastQuestionAnswers, setPastQuestionAnswers] = useState([]);

  const fetchPastQuestionAnswers = async () => {
    try {
      let fetchedQAs;
      if (domainParam) {
        fetchedQAs = await DataStore.query(QuestionAnswer, qa => qa.and(qa => [
          qa.userEmail.eq(userEmail),
          qa.measuredTopics.contains(domainParam)]
        ));
      } else {
        fetchedQAs = await DataStore.query(QuestionAnswer, qa => qa.userEmail.eq(userEmail));
      }
      const sortedQAs = fetchedQAs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setPastQuestionAnswers(sortedQAs);
    } catch (error) {
      console.error('Error fetching QuestionAnswers:', error);
    }
  };

  useEffect(() => {
    fetchPastQuestionAnswers();
  }, [domainParam]);

  const renderChart = () => {
    switch (type) {
      case 'level':
        return (
          <div>
            <LevelHistory/>
          </div>
        )
        case 'xp':
          return (
            <div>
              <XPHistory/>
            </div>
          )
        default: return null;
    }  
  }

  const renderPastQuestions = () => {
    return (
    <Box sx={{marginTop: '80px'}}>
        <Typography variant="h4">Past Questions</Typography>
        {pastQuestionAnswers.map((questionAnswer, index) => {
          const { id, questionText, wasCorrect, measuredTopics, correctAnswer } = questionAnswer;

          console.log(questionAnswer)

          const paperClasses = classes.paper + " " + (wasCorrect ? classes.paperCorrect : classes.paperInCorrect)

          return (
            <Paper key={id} className={paperClasses}>
              <Box key={index}>
                
                <Typography variant="body1">{questionText}</Typography>
                
                
                <Typography variant="body1" color="#dfb774" >{correctAnswer}</Typography>
                {/* {measuredTopics.map((topic, index) => (
                  <Typography key={topic} variant="subtitle1">{topic}</Typography>
                ))} */}
              </Box>
            </Paper>
          )
        })}
      </Box>
    )
} 

  return (
    <Box sx={{ padding: 2 }}>
      <TopBar />
      {/* <HistoryTypeSelector/> */}
      {renderChart()}
      {renderPastQuestions()}
    </Box>
  )
}